.priority-button {
  display: flex;
  height: 56px;
  padding: 5px;
  cursor: pointer;
  margin-left: 43px;
  align-items: center;
  position: relative;
  cursor: pointer;

  & > img {
    width: 48px;
  }
  & > span {
    padding-left: 11px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #292a34;
  }
}

.in-table {
  margin: 0;
  height: 30px;
  &:hover {
    background-color: #f4f4f4;
    cursor: pointer;
  }
}

.priority-select {
  position: absolute;
  top: 13px;
  left: 0;
  background-color: #fff;
  align-items: center;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
}

.blockChange {
  cursor: default;
  pointer-events: none;
}
