.we-supply {
  margin-left: 42px;
  font-size: 14px;
  // overflow: auto;

  & > div:last-child {
    margin-bottom: 0px;
  }

  & > div {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
  }

  & input[type="radio"] {
    display: none;
  }
}

.we-supply__radio {
  border-radius: 50%;
  width: 18px;
  height: 18px;
  border: 2px solid rgba(41, 42, 52, 0.5);
  position: relative;
}

.we-supply input[type="radio"]:checked + .we-supply__radio,
#counterparty-yes:checked ~ .radio-counterparty > .we-supply__radio,
#credit-limit-specify:checked ~ .radio-counterparty > .we-supply__radio {
  border-color: #292a34;

  &::after {
    position: absolute;
    content: "";
    width: 12px;
    height: 12px;
    background-color: #292a34;
    border-radius: 50%;
    left: 1px;
    top: 1px;
  }
}

.input-form__short {
  width: 50%;
}

.counterparty__radio-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  flex-shrink: 0;
}

.input-short__radio {
  display: none !important;
  flex-direction: column;
  align-items: flex-start !important;
  margin-left: 42px;
  margin-bottom: 20px;
  gap: 8px;

  & > label {
    margin-left: 42px;
  }
  & > input {
    width: 70%;
  }
}

#counterparty-yes:checked ~ .input-short__radio {
  display: flex !important;
}

#credit-limit-specify:checked ~ .input-short__radio {
  display: flex !important;
}

.we-supply__radio {
  flex-basis: 18px;
}

.radio-counterparty {
  display: flex;
  align-items: center;
  gap: 10px;
}

.credit-limit-specify {
  margin-top: 20px;
}

#credit-sum {
  position: relative;

  &::after {
    position: absolute;
    content: "руб.";
    color: #292a34;
    opacity: 0.5;
    top: 33%;
    right: 70px;
  }
}

textarea {
  height: 86px !important;
  align-items: center;
}

textarea::placeholder {
  opacity: 0.5;
  font-size: 14px;
}

.download-file {
  display: flex;
  align-items: center;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  width: 194px;
  height: 50px;
  justify-content: space-evenly;
  gap: 5px !important;
  margin-left: 42px !important;
}

input[type="file"] {
  display: none;
}

div > .file-download {
  margin-left: 42px;
}

.doc-list {
  display: flex;
  width: 404px;
  margin-left: 42px;
  margin-bottom: 20px;
  justify-content: space-between;
  align-items: center;

  & > .download-file {
    flex-shrink: 0;
    align-items: center;
  }
}

.form {
  // min-height: 100%;
  // overflow-y: auto;
  width: 100%;
}
.warningText {
  margin-left: 51px;
}

.p__drop-content {
  margin: 10px 0 10px 10px;
  display: flex;
  gap: 8px;
  align-items: center;
}
.textarea {
  border: 1px solid lightgray;
  /* flex: 1; */
  width: 100%;
}
//
.checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.we-supply input[type="checkbox"]:checked + .we-supply__radio,
#counterparty-yes:checked ~ .radio-counterparty > .we-supply__radio,
#credit-limit-specify:checked ~ .radio-counterparty > .we-supply__radio {
  border-color: #292a34;

  &::after {
    position: absolute;
    content: "";
    width: 12px;
    height: 12px;
    background-color: #292a34;
    border-radius: 50%;
    left: 1px;
    top: 1px;
  }
}
.input-form {
  padding: 10px;
  width: 90%;
  overflow: auto;
  margin-bottom: 5px;
  margin-top: 5px;
  flex-wrap: wrap;
}
.marginInput {
  margin: 10px 0;
  margin-left: 42px;
}

.file-uploaded__block {
  padding-top: 5px;
  a {
    color: #00c;
  }

  a:hover {
    text-decoration: underline;
  }
}

.input-form input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none; // Yeah, yeah everybody write about it
}

input[type="number"],
input[type="number"]:hover,
input[type="number"]:focus {
  appearance: none;
  -moz-appearance: textfield;
}

.unavailableForm {
  pointer-events: none;
}

.additional-header {
  align-items: center;
  margin: 10px 43px;
  color: #292a34;
}
