.turn {
  &:hover {
    color: #01b0e9;
  }
  margin-bottom: 15px;
}

.trush {
  &:hover {
    color: #01b0e9;
  }
  //  margin-bottom: 15px;
}

.trush_false {
  //  margin-bottom: 15px;
  color: #757575;
}

.bp-item-task-menu {
  position: absolute;
  padding: 29px 27px 25px 19px;
  background: #ffffff;
  box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  z-index: 1;
  right: 5px;
  top: 44px;
}

.img_power_button {
  width: 18px;
  margin-right: 15px;
  // margin-bottom: 15px;
}

.img_power_trush {
  width: 18px;
  margin-right: 15px;
  // margin-bottom: 15px;
}
