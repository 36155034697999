.business {
  display: grid;
  grid-template-columns: 1fr;
  height: calc(100% - 63.98px);
}

.business-open {
  grid-template-columns: 2fr 1fr;
}

.business__main-content {
  overflow: hidden;
  background-color: #f4f4f4;
  height: 100%;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: #ffffff;
    padding: 23px 18px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);

    &-left {
      display: flex;
      gap: 27px;
      align-items: center;
      justify-content: space-between;

      &__position {
        display: flex;
        gap: 18px;
        align-items: center;
        cursor: pointer;
      }

      &__select {
        position: relative;
      }
    }

    &-right {
      display: flex;
      gap: 18px;
      align-items: center;

      &__btn {
        border: none;
        background: transparent;
        cursor: pointer;
      }
    }
  }
}

.p-black {
  font-weight: 400;
  font-size: 16px;
  color: #292a34;
  min-width: 103px;
}
.project-name {
  min-width: 150px;
}

.p-grey {
  font-weight: 400;
  font-size: 14px;
  color: rgba(41, 42, 52, 0.5);
  border: none;
  background-color: transparent;
}
.project-section-name {
  min-width: 130px;
}

button {
  cursor: pointer;
}

#business-select-menu__label {
  background: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  padding: 8px 16px 8px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 66px;
  cursor: pointer;

  & > div {
    display: flex;
    gap: 10px;
    align-items: center;
  }
}

#business-select-menu__list {
  display: none;
  position: absolute;

  & > li:not(:last-child) {
    border-bottom: 1px solid #c4c4c4;
  }
}

.business-select-menu__img {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  overflow: hidden;
  & > img {
    width: 34px;
  }
}

#business-select-menu:checked ~ #business-select-menu__list {
  display: block;
  margin-top: 0;
  margin: 0 4px;
  left: 0;
  right: 0;

  border: 1px solid #c4c4c4;
  border-top: none;
}

.business-select-menu__list-item {
  background: #ffffff;
  border: none;
  width: 100%;
  cursor: pointer;

  & > div {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: flex-start;
    padding: 6px 10px;
  }
}

.initial-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;

  & > img:first-child {
    transform: rotate(180deg);
    opacity: 0.5;
  }

  & > img {
    width: 10px;
    height: 5px;
  }
}

.dropdown-menu__bpItem {
  position: absolute;
  right: 0;
  top: 0;
}

.business__main-content__header-right__btn {
  width: 36px;
  height: 36px;
  border-radius: 4px;
  align-items: center;
  &__selected-icon {
    background: transparent;
    width: 36px;
    svg {
      path {
        stroke: #01b0e9;
      }
    }
    span {
      color: #01b0e9;
    }
    &:hover {
      width: 36px;
      height: 36px;
      border-radius: 4px;
      background: #f4f4f4;
    }
  }

  &:hover {
    width: 36px;
    height: 36px;
    background: #f4f4f4;
  }

  //&:hover,
  // &:active {
  //    background: #f4f4f4;

  // }
}

.dependencies {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 12px;
  padding-bottom: 22px;
  padding-left: 60px;
  max-width: 100%;
  // margin-left: 70px;

  &__content {
    flex-grow: 1;
    padding: 0 20px;
  }
}

#dependencies-checkbox:checked + span {
  background-color: lightgray;

  &::before {
    left: 0;
    background-color: grey;
  }
}

.dependencies__content-list {
  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background: #f4f4f4;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    padding: 18px 16px;
    margin-bottom: 12px;
    margin-left: auto;

    &__right {
      display: flex;
      align-items: center;
      min-width: 383px;
      flex-shrink: 0;
    }

    &__right-active {
      gap: 1em;
    }

    &-completed {
      opacity: 0.5;
    }

    &__title {
      flex-grow: 1;
      padding: 0 23px;
      padding-right: 12px;
      & > p {
        margin-bottom: 5px;
      }
    }
    &__right__status {
      width: 36px;
      height: 36px;
      border: 1px solid;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;

      &-completed {
        background: rgba(67, 160, 71, 0.1);
        border-color: #43a047;
      }
      &-pause {
        background: rgba(255, 153, 0, 0.1);
        border-color: #ff9900;
      }
    }

    &__right__logo {
      border-radius: 50%;
      border: 3px solid #fff;
      width: 40px;
      height: 40px;
      overflow: hidden;

      & > img {
        width: 100%;
      }
    }

    &__right__priority {
      display: flex;
      justify-content: space-between;
      gap: 6px;
      margin-right: 14px;
      margin-left: 8px;

      &-indicator {
        border: 1px solid #01b0e9;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: #fff;

        &__active {
          background-color: #01b0e9;
        }
      }
    }

    &__arrow {
      cursor: pointer;
    }
  }
}

.dependencies__content-list__task-block {
  width: 100%;
  display: grid;
  grid-template-columns: 88px 1fr;
}

.dependencies__content-list__item-dropdown {
  padding: 0;
  margin: 0;
  box-shadow: none;
  background-color: white;
  position: relative;
}

.dependencies__content-list__item-dropdwn__horizontal-line {
  width: 54px;
  padding-right: 14px;
  flex-shrink: 0;
}

.dependencies__content-list__item-dropdown::before {
  content: "";
  border-bottom: 1px dashed #8b8c91;
  position: absolute;
  top: 54%;
  width: 40px;
  left: -60px;
  padding: 1px;
}

.dependencies__content-list__task-block__vertical-line {
  grid-column-start: 1;
  grid-column-end: 1;
  margin-left: 27px;
  & > div {
    height: calc(100% - 47px);
    border-left: 1px dashed #8b8c91;
  }
}

.click {
  background: #e5f6ff;
}

.dependencies__content-list__item {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: #f4f4f4;
  // box-shadow: 0px 4px 5px rgb(0 0 0 / 20%);
  border-radius: 4px;
  padding: 18px 16px;
  margin-bottom: 12px;
  margin-left: auto;
  grid-column-start: 1;
  grid-column-end: 4;
}

.dependencies__content-list__task-block__gorizont-wrapper {
  grid-column-start: 2;
  grid-column-end: 12;
}

.dependencies__content-list__task-block
  > .dependencies__content-list__item:last-child {
  background-color: red;
}

.business__main-content__list-block {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 4px;
  max-height: calc(100% - 138.44px);

  &__container {
    overflow: auto;
    height: calc(100vh - 77.5px);
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    &::-webkit-scrollbar-thumb {
      width: 7px;
      background: rgba(0, 0, 0, 0.15);
      border-radius: 3px;
    }

    & > div {
      margin-right: 7px;
    }
  }

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 0;
    margin-left: 23px;
    margin-right: 86px;
    gap: 2em;

    & > div {
      display: flex;
      justify-content: space-between;
    }

    & > div:first-child {
      gap: 4em;
    }

    & > div:last-child {
      width: 580px;
    }
  }

  &__item > div:first-child {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    position: relative;

    & > div {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 15px 20px;
    }

    & > .business__main-content__list-block__item-right {
      flex-basis: 43.6%;
    }

    &__number {
      margin-right: 34px;
    }

    &__message {
      position: relative;
      margin-right: 44px;
      cursor: pointer;
    }

    &__name > p,
    &__project > p {
      margin-bottom: 5px;
    }

    &-right {
      gap: 14px;
    }
  }
}

.business__main-content__list-block__item__message {
  position: relative;
  cursor: pointer;
}

.business__main-content__list-block__item {
  background-color: #ffffff;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  margin-bottom: 12px;
  margin-left: 5px;
}

.business__main-content__list-block__item {
  position: relative;
}

.business__main-content__list-block__item::before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  bottom: 0;
  /***** background-color: rgba(1, 176, 233, 1);*****/
  height: 4px;
  border-radius: 0px 0px 4px 4px;
}

.business__main-content__list-block__item > div:first-child {
  & > .business__main-content__list-block__item-left {
    gap: 40px;
    padding-left: 23px;
    align-items: center;
  }
}

.business__main-content__list-block__item__status {
  display: flex;
  align-items: center;
  gap: 10px;
  // color: #436ea0;
  font-size: 16px;
  flex-shrink: 0;
  width: 163px;
  cursor: pointer;

  & button {
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    // border: 1px solid #436ea0;
    // background: rgba(67, 110, 160, 0.1);
    border-radius: 4px;
    cursor: pointer;
  }
}

.business__main-content__list-block__item__logo__task {
  margin-right: 16px;
  margin-left: 16px;
}

.business__main-content__list-block__item__logo {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #01b0e9;

  & > img {
    width: 34px;
    height: 34px;
  }
  & > span {
    position: absolute;
    top: 50%;
    font-size: 16px;
    line-height: 0px;
    color: #fff;
  }
}

.dependencies__content-list__item__btn {
  display: flex;
  gap: 14px;
  align-items: center;
}

.business__main-content__list-block__title-active {
  margin-right: 46px;
  & > div:last-child {
    gap: 1.5em;
    // width: auto;
    margin-right: 95px;
  }
}
.business__main-content__list-block__item-active
  > div:first-child
  > .business__main-content__list-block__item-right {
  padding-right: 54px;
  & .business__main-content__list-block__item__project {
    margin-left: 138px;
  }

  & .business__main-content__list-block__item__status {
    width: 84px;
  }
}

.dependencies__content-list__item-active {
  padding-right: 10px;
  & .dependencies__content-list__item__right {
    & .business__main-content__list-block__item__status {
      width: 54px;
      margin-left: 30px;
    }

    // & .business__main-content__list-block__item__logo__task {
    //   // margin-right: 47px;
    //   // margin-left: 62px;
    // }
  }
}

.business__main-content__header-left__position:hover {
  & > p {
    color: #01b0e9;
  }
  & > svg path {
    stroke: #01b0e9;
  }
}

#business-select-menu__label {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #292a34;
  width: 288px;
  height: 50px;
}

#business-select-menu__label:hover {
  border-color: #292a34;
}

/*
.business__main-content__header-right__btn:hover > svg path,
.business__main-content__header-right__btn:focus > svg path {
  stroke: #01b0e9;
}
*/

.business__main-content__list-block__item__name {
  color: #0c0;
  min-width: 100px;
}

.business__main-content__list-block__item-status {
  margin-right: 80px;
  // width: 580px;
}

.business__main-content__list-block__item__deadline {
  width: 100px;
}

.business__main-content__list-block__item__project {
  width: 120px;
  margin-left: 163px;
  margin-right: 35px;
}

.dependencies__content-list__project {
  margin-right: 16px;
}

#excel {
  position: relative;
}

#excel-menu {
  position: absolute;
  min-width: 213px;
  min-height: 120px;
  background: #ffffff;
  box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  bottom: -123px;
  right: 0;
  z-index: 99999;
  padding: 16px 16px;
  display: flex;
  flex-direction: column;

  & > div {
    display: flex;
    align-items: center;
    gap: 13px;
    font-size: 16px;
    line-height: 24px;
    color: #292a34;
    text-align: left;
    cursor: pointer;
  }
}

.excel-item {
  width: 188px;
  height: 44px;
}

.excel-menu__select-column {
  bottom: -316px !important;

  & .excel-item {
    width: 188px;
    height: 30px;
  }

  & > div:first-child {
    display: flex;
    flex-direction: column;
    & > div > input {
      margin-right: 15px;
    }
  }

  & > div:last-child {
    width: 100%;
    background-color: #01b0e9;
    color: white !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    padding: 6px 0;
  }
}
.template-name {
  padding: 18px 35px;
  min-width: 413px;
}
.start_sample {
  margin-right: 260px;
}

.redirect-to-general-list {
  display: flex;
  justify-content: end;
  padding-bottom: 10px;
  padding-right: 20px;
  & > span {
    cursor: pointer;
    color: rgb(67, 110, 160);
    text-decoration: underline;
  }
}
.loading {
  height: 4px;
  background: #01b0e9;
}

.business-filter {
  cursor: default;
  background-color: #ffffff;
  display: flex;
  top: 345px;
  left: 345px;
  box-shadow: 3px 6px 16px #00000033;
  z-index: 10000;
  position: absolute;
  border-radius: 8px;
  padding: 43px 54px 40px 40px;
  &_h2 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #292a34;
  }

  &__block-left {
    padding-right: 63px;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    > h2 {
      cursor: pointer;
      margin-bottom: 42px;
    }
    p {
      cursor: pointer;
      font-size: 18px;
    }
  }
  &__block-right {
    max-height: 630px;
    overflow: auto;
    width: 100%;
    padding-left: 50px;
    > h2 {
      margin-bottom: 19px;
      margin-right: 7px;
      text-align: right;
      font-weight: 400;
      font-size: 18px;
      // color: #292a34;
    }
  }
}

.bf {
  &_pText {
    padding-left: 8px;

    font-family: "Roboto";
    font-style: normal;
    font-size: 14px;
    line-height: 16px;
    color: #292a34;
  }

  // &_block__scroll {
  //   max-height: 180px;
  //   overflow: auto;
  // }
  &__block-One {
    margin-bottom: 19px;
    display: flex;
    justify-content: space-between;
    &__divInput {
      position: relative;
      width: 180px;
      display: flex;
      flex-direction: column;
    }
  }

  &__block-Two {
    position: relative;
    display: flex;
    flex-direction: column;
    &_icon {
      position: absolute;
      top: 38px;
      left: -25px;
    }
    &_icon_individ {
      position: absolute;
      top: 22px;
      left: -25px;
    }
  }
  &__block-Three {
    position: relative;
    display: flex;
    flex-direction: column;
    &_icon {
      position: absolute;
      top: 24px;
      left: -24px;
    }
  }
  &__block-Four {
    margin-top: 10px !important;
    position: relative;
    margin: 22px 0px 23px 20px;
    display: flex;
    flex-direction: column;
    &_icon {
      cursor: pointer;
      position: absolute;
      top: -5px;
      left: -15px;
    }
    &_text {
      cursor: pointer;
      margin-left: 20px;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      color: #01b0e9;
    }
  }
  &__block-Five {
    position: relative;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
  }
  &__block-Six {
    cursor: pointer;
    position: relative;
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
    &__blockIcons {
      align-items: center;
      display: flex;
      margin-top: 22px;
      padding-left: 8px;
    }
    &_icons {
      margin-right: 8px;
    }
    &_position {
      position: relative;
      > div {
        position: absolute;
        top: 11px;
        left: 11px;
      }
    }
  }
  &__block-Six_position_svgIndivid {
    left: 17px !important;
  }
  &__block-Seven {
    margin-left: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &_button {
      transition: all 0.2s;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      padding: 10px 20px;
      height: 50px;
      background: #01b0e9;
      border: 1px solid #01b0e9;
      border-radius: 4px;
      color: #ffffff;
      &:hover {
        background: #0086b3;
      }
    }
    // &_icon {
    // }
  }
  &__icons {
    position: absolute;
    top: -5px;
    left: -25px;
  }
  &__block_close-icons {
    > svg {
      border: 1px solid red;
      border-radius: 50%;
      color: red;
    }
  }
}

.status_header {
  margin-right: 90px;
}

// .img_menu_bp_item {
//   // margin-left: 300px;
// }

.item_id {
  font-weight: 400;
  font-size: 18px;
  color: #292a34;
}
.filter-icon-text {
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}
