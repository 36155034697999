$blue-main: #1BAAF0;
$blue-lighter: #99D7F5;
$blue-lightest: #F3FAFE;
$blue-dark: #E6ECEF;
$blue-darker: #02405E;
$black-lightest: #F2F2F2;
$black-white: #FFFFFF;

$light_accent: #54C7FF;
$black-lighter: #E0E0E0;
$black-middle: #A8A8A8;
$black-darker: #707070;
$black-darkest: #212121;
$yellow-main: #FFDE89;
$yellow-lighter: #FFFDF7;
$yellow-darker: #B9932F;
$green-main: #27CBA6;
$green-darker: #008C6D;
$green-lighter: #F2FEFC;
$red-main: #FF6666;
$red-lighter: #FFF2F2;
$red-darker: #CC0000;
$purple-main: #BB6BD9;
$purple-figma: #7B61FF;
$purple-darker: #B201F1;
$purple-lightest: #FBF3FE;

$black: rgba(0, 0, 0, 0.87);
$grey_border: 1px solid rgba(0, 0, 0, 0.12);
$blue_background: rgba(27, 170, 240, 0.1);
$disabled: rgba(0, 0, 0, 0.02);
$disabled_text: #2021249f;


//@font-face {
//  font-family: 'MyWebFont'
//  src: url('Roboto-Regular.ttf') format('truetype')
//}
