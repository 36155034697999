.save_bp {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 400px;
  height: 170px;
  z-index: 9999;
  // display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.25);
  margin: auto;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 3px 6px 16px #00000033;
  color: black;
}

.name_bp {
  text-align: center;
  padding-top: 20px;
}

.input_for_name {
  text-align: center;
  padding-top: 20px;
}

.input_save_bp {
  width: 350px;
  border: 1px solid gray;
  border-radius: 3px;
  height: 35px;
}

.input_save_bp::-webkit-input-placeholder {
  color: black;
}

.button_for_bp {
  padding: 20px 0 0 75px;
}

.cancel_button {
  width: 150px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid gray;
  font-weight: 400;
  font-size: 14px;
  color: #292a34;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.cancel_button:hover {
  color: #01b0e9;
}

.save_button {
  width: 150px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid gray;
  font-weight: 400;
  font-size: 14px;
  color: white;
  border: none;
  background-color: transparent;
  cursor: pointer;
  background: #01b0e9;
}

.save_button:hover {
  background-color: #6db7d0;
  border-color: #6db7d0;
}
