@keyframes preloder_jump {
    0% {
        transform: translateY(0); }
    50% {
        transform: translateY(-80px); }
    100% {
        transform: translateY(0); } }

@keyframes shadow_grow {
    0% {
        transform: scaleX(1); }
    50% {
        transform: scaleX(1.4); }
    100% {
        transform: scaleX(1); } }

@keyframes spin {
    0% {
        transform: rotate(0); }
    100% {
        transform: rotate(360deg); } }
