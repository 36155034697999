@mixin flex($justify_content: false, $align_items: false) {
    display: flex;
    @if $justify_content {
        justify-content: $justify_content; }
    @if $align_items {
        align-items: $align_items; } }

@mixin ellipsis($clamp: false) {
    overflow: hidden;
    text-overflow: ellipsis;
    @if $clamp {
        display: -webkit-box;
        -webkit-line-clamp: $clamp;
        -webkit-box-orient: vertical; }
    @else {
        white-space: nowrap; } }

@mixin absolute($top: false, $right: false, $bottom: false, $left: false) {
    position: absolute;
    @if $top {
        top: $top; }
    @if $right {
        right: $right; }
    @if $bottom {
        bottom: $bottom; }
    @if $left {
        left: $left; } }

@mixin grid_2_cols {
    display: grid;
    grid-template-columns: 105px 1fr;
    column-gap: 35px; }

@mixin size($width, $height) {
    width: $width;
    height: $height; }
